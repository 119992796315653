import React from 'react'

const Partners = (props) => {
    return (
        <section className="brand_sec">
            <div className="container">
                <div className="tophead">
                    <h2 className="title1">
                        our Partners
                    </h2>  
                </div>
                <div className="partnerlogo">
                    <div className="item">
                        <a href="https://mywindsock.com/" target="_blank">
                        
                            <img src={ require("../../../assets/images/partnr7.png")} alt="" />
                        </a>
                    </div>
                    <div className="item">
                        <a href="https://www.naturtrack.com/en/" target="_blank">
                            <img src={ require("../../../assets/images/partnr8.png")} alt="" />
                        </a>
                    </div> 
                    <div className="item">
                        <a href="https://www.nicci.no/" target="_blank">
                            <img src={ require("../../../assets/images/nicci.png")} alt="" />
                        </a>
                    </div>
                    <div className="item">
                        <a href="https://bikeep.com/" target="_blank">
                            <img src={ require("../../../assets/images/partnr9.png")} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default Partners;
